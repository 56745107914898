import Image1 from '../../assets/about.jpg'
import Image2 from '../../assets/testimonial2.png'
import Image3 from '../../assets/testimonial3.png'

export const Data = [
    {
        id: 1,
        image: Image1,
        title: "ADX",
        description:
            "A really good App, all aspects of the project were followed step by step and with good results.",
    },
    {
        id: 2,
        image: Image2,
        title: "Harry Clinton",
        description:
            "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
        id: 3,
        image: Image3,
        title: "Sara Cill",
        description:
            "A really good job, all aspects of the project were followed step by step and with good results.",
    },
];