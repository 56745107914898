// import Work1 from "../../assets/work1.jpg"
// import Work2 from "../../assets/work2.jpg"
// import Work3 from "../../assets/work3.jpg"
// import Work4 from "../../assets/work4.jpg"
// import Work5 from "../../assets/work5.jpg"

import Work1 from "../../assets/g1.png"
import Work2 from "../../assets/g2.png"
import Work3 from "../../assets/g3.png"
import Work4 from "../../assets/g4.png"
import Work5 from "../../assets/g5.png"


export const projectsData = [
    {
        id: 1,
        image: Work1,
        title: "Web design",
        category: "web",
    },
    {
        id: 2,
        image: Work2,
        title: "App movil",
        category: "app",
    },
    {
        id: 3,
        image: Work3,
        title: "Brand design",
        category: "design",
    },
    {
        id: 4,
        image: Work4,
        title: "App movil",
        category: "app",
    },
    {
        id: 5,
        image: Work5,
        title: "Web design",
        category: "web",
    },
];

export const projectsNav = [
    {
        name: "All",
    },
    {
        name: "Web",
    },
    {
        name: "App",
    },
    {
        name: "Design",
    },
]